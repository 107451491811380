//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    treeHoleList: {
      type: Array
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    deleteTreeHole: function deleteTreeHole(id) {
      this.$emit("deleteTreeHole", id);
    }
  }
};